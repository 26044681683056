import { Box } from '@mui/material';
import { ReactComponent as IconArrow } from 'assets/icons/chevron-down.svg';
import { PATHS } from 'common/constants';
import { useOutsideClick } from 'common/helpers';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './AdvantageMenu.module.scss';


const AdvantageMenu = () => {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dropRef = useRef(null);

  const handleNavigate = (path) => {
    if (open) {
      navigate(path);
      setOpen(false);
    } else {
      setOpen((prev) => !prev);
    }
  };

  useOutsideClick(dropRef, () => setOpen(false), open);

  return (
    <Box className={classes.links} ref={dropRef}>
      <span className={classes.link}>
        <p
          className={pathname === PATHS.advantages ? classes.activeLink : ''}
          onClick={() => handleNavigate(PATHS.advantages)}
          title={t('Advantages')}
        >
          {t('Advantages')}
        </p>
        <IconArrow className={open ? classes.arrowReverse : ''} />
      </span>
      {open && (
        <span
          className={`
          ${classes.secondLink}
          ${classes.link}
        `}
        >
          <p
            className={
              pathname === PATHS.afterCourses ? classes.activeLink : ''
            }
            onClick={() => handleNavigate(PATHS.afterCourses)}
            title={t('AfterCourses')}
          >
            {t('AfterCourses')}
          </p>
        </span>
      )}
    </Box>
  );
};

export default AdvantageMenu;
