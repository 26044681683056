import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { generateRel } from 'common/helpers';
import classes from './CustomButton.module.scss';


const CustomButton = ({ text, theme, isArrow, path = '', isRounded, outlined, MyClass, ...props }) => {
  const themes = {
    yellow: 'yellow',
    white: 'white',
    transparent: 'transparent',
  };
  const theTheme = () => {
    switch (theme) {
    case themes.yellow :
      return classes.btnYellowTheme;
    case themes.white :
      return classes.btnWhiteTheme;
    case themes.transparent :
      return classes.btnTransparentTheme;
    default:
      return classes.btnYellowTheme;
    }
  };
  const rounded = isRounded ? classes.rounded : '';
  const isOutlined = outlined ? classes.outlined : '';

  const options = {
    disableRipple: true,
    color: 'info',
    component: path ? 'a' : 'button',
    target: path ? '_blank' : '',
    ...(path ? { href: path, ...generateRel(path) } : {}),
  };
  return (
    <Button
      className={`${classes.btn} ${theTheme()} ${rounded} ${isOutlined} ${MyClass}`}
      {...props}
      {...options}
    >
      <span className={classes.btnText}>
        {text}
      </span>
      {!!isArrow && <ArrowForwardIcon />}
    </Button>
  );
};

export default CustomButton;
